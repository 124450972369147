<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allStudents"
      sort-by="name"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="3">
            <v-text-field
              v-model="searchkey"
              label="تحديد اسم، كنية،اسم الأب, اسم مستخدم ,جوال للبحث"
              prepend-icon="mdi-seach"
              clearable
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              ref="classes"
              v-model="classId"
              :items="classes"
              placeholder="جلب حسب شعبة محدد"
              required
              item-text="name"
              item-value="id"
              chips
            ></v-autocomplete>
          </td>
          <td>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              @click="searchStudents"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
          <td>
            <export-excel
              class="btn btn-default outlined fab ma-2"
              :data="allStudents"
              :fields="exportHeaders"
              worksheet="My Worksheet"
              name="Student.xls"
            >
              <v-btn outlined color="green"> تصدير البيانات</v-btn>
            </export-excel>
          </td>
          <td colspan="2"></td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>الطلاب</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewStd = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-dialog v-model="dialog" width="700px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل بيانات الطالب</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card flat width="700px">
                      <v-card-text>
                        <v-form ref="form" v-model="valid">
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="الاسم"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.fatherName"
                                  label="اسم الأب"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" sm="6">
                                 <v-menu
                                  ref="menu"
                               v-model="menu"
                              :close-on-content-click="false"
                             :nudge-right="40"
                             :return-value.sync="editedItem.birthdate"
                             lazy
                             transition="scale-transition"
                            offset-y
                           full-width
                           min-width="290px" >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="editedItem.birthdate"
            label="تاريخ الميلاد"
          
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="editedItem.birthdate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn flat color="primary" @click="$refs.menu.save(editedItem.birthdate)">OK</v-btn>
        </v-date-picker>
      </v-menu>
                               
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.mobile"
                                  label="الموبايل"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mb-2">
                              <v-col cols="12">
                                <v-autocomplete
                                  ref="classes"
                                  v-model="editedItem.classId"
                                  :items="classes"
                                  placeholder="اختر الشعبة المراد تسجيل الطالب بها"
                                  required
                                  item-text="name"
                                  item-value="id"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                     <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.registrationFee"
                          type="number"
                          label="القسط الكامل"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.numberOfInstallments"
                          type="number"
                          label="عدد الأقساط"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                       <v-row class="mb-2">
                    <v-col cols="12">

                     <v-menu
                                  ref="menuFirstInstallmentDate"
                               v-model="menuFirstInstallmentDate"
                              :close-on-content-click="false"
                             :nudge-right="40"
                             :return-value.sync="editedItem.firstInstallmentDate"
                             lazy
                             transition="scale-transition"
                            offset-y
                           full-width
                           min-width="290px" >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="editedItem.firstInstallmentDate"
            label="تاريخ أول دفعة"
          
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="editedItem.firstInstallmentDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="menuFirstInstallmentDate = false">Cancel</v-btn>
          <v-btn flat color="primary" @click="$refs.menuFirstInstallmentDate.save(editedItem.firstInstallmentDate)">OK</v-btn>
        </v-date-picker>
      </v-menu>

                     
                    </v-col>
                    
                  <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.fingerPrintNum"
                          label="رقم البصمة"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                            <v-row class="mb-2">
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.userName"
                                  :rules="userNameRules"
                                  label="اسم المستخدم"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.password"
                                  :append-icon="
                                    show1 ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="passwordRules"
                                  :type="show1 ? 'text' : 'password'"
                                  label="كلمة المرور"
                                  @click:append="show1 = !show1"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn color="blue darken-1" text @click="close">الغاء</v-btn>
                <v-btn color="blue darken-1" text @click="save">حفظ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn rounded color="primary" dark @click="editStudent(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn rounded class="mr-2" color="error" @click="deleteStudent(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getStudents">Reset</v-btn>
      </template>
    </v-data-table>
    <AddStudent :show.sync="showAddNewStd" @studentAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddStudent from "../students/AddStudent.vue";
const apiService = new APIService();
export default {
  components: { AddStudent },
  data: () => ({
    dialog: false,
    valid: true,
    menu: false,
    menuFirstInstallmentDate:false,
    showAddNewStd: false,
    isGettingData: false,
    searchkey: "",
    headers: [
      {
        text: "الاسم",
        align: "start",
        value: "name",
      },
      { text: "اسم الأب", value: "fatherName" },
      { text: "تاريخ الميلاد", value: "birthdate" },
      { text: "الموبايل", value: "mobile" },
      { text: "اسم المستخدم", value: "userName" },
      { text: "الشعبة", value: "className" },
      { text: "رقم البصمة", value: "fingerPrintNum" },
      { text: "العمليات", value: "action", sortable: false },
    ],

    exportHeaders: {
      Name: "name",
      "Father Name": "fatherName",
      Mobile: "mobile",
      "User Name": "userName",
      "Class Name": "className",
    },
    classes: [],
    classId: 0,
    show1: false,
    allStudents: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      fatherName: "",
      birthdate: "",
      mobile: "",
      username: "",
      password: "",
      registrationFee: 0,
      numberOfInstallments: 0,
      firstInstallmentDate:"",
      fingerPrintNum:"",

    },
    defaultItem: {
      name: "",
      fatherName: "",
      birthdate: "",
      mobile: "",
      username: "",
      password: "",
      registrationFee: 0,
      numberOfInstallments: 0,
      firstInstallmentDate:"",
      fingerPrintNum:"",

    },
    nameRules: [
      (v) => !!v || "يجب ادخال الاسم",
      (v) => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],
    userNameRules: [
      (v) => !!v || "يجب ادخال اسم المستخدم",
      (v) => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],
    passwordRules: [
      (v) =>
        (!!v && v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],
    firstInstallmentDateRules:[ (v) => !!v || "يجب ادخال تاريخ أول دفعة"]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "طالب جديد" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getStudents();
    this.getClasses();
  },

  methods: {
    getStudents() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getStudents()
        .then((response) => {
          that.isGettingData = false;
          this.allStudents = response.students;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    searchStudents() {
      // if (this.classId == 0 && !this.searchkey) return;
      this.isGettingData = true;
      const that = this;
      apiService
        .searchStudents(this.classId, this.searchkey)
        .then((response) => {
          that.isGettingData = false;
          this.allStudents = response.students;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    refreshData() {
      this.getStudents();
    },
    editStudent(item) {
      this.editedIndex = this.allStudents.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.name = item.name;
      this.editedItem.birthdate = item.birthdate;
      this.editedItem.fatherName = item.fatherName;
      this.editedItem.mobile = item.mobile;
      this.editedItem.classId=item.classId;
      this.editedItem.userName = item.userName;
      this.editedItem.password = item.password;
      this.editedItem.registrationFee=item.registrationFee;
      this.editedItem.numberOfInstallments=item.numberOfInstallments;
      this.editedItem.firstInstallmentDate =item.firstInstallmentDate;
      this.editedItem.fingerPrintNum=item.fingerPrintNum;
      this.dialog = true;
    },
    deleteStudent(item) {
      var studentObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف الطالب")) {
        setTimeout(() => {
          apiService
            .deleteStudent(studentObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        setTimeout(() => {
          apiService
            .updateStudent(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              //this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
        // } else {
        //   this.desserts.push(this.editedItem);
        // }
      }
    },
    getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.classes = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
  },
};
</script>
